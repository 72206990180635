import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "lifebike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "lifebike-elcykel-upptäck-alla-serier-och-hitta-din-perfekta-elcykel"
    }}>{`Lifebike Elcykel: Upptäck Alla Serier och Hitta Din Perfekta Elcykel`}</h1>
    <p>{`När det kommer till pålitliga och högpresterande elcyklar, står Lifebike i framkant med sina innovativa och hållbara modeller. Oavsett om du är en daglig pendlar, en förälder som söker en pålitlig familjeelcykel, eller en leveranskurir som behöver en kraftfull cykel för jobbet, har Lifebike en elcykel som passar dina behov. Här får du en översikt över Lifebikes olika serier och deras unika egenskaper.`}</p>
    <h2 {...{
      "id": "lifebike-c-go-serie"
    }}>{`Lifebike C-go Serie`}</h2>
    <h3 {...{
      "id": "lifebike-c-go-grå-g7-elcykel-2024"
    }}>{`Lifebike C-go Grå G7 Elcykel 2024`}</h3>
    <p>{`Lifebike C-go är den perfekta familjeelcykeln som kombinerar stil, komfort och kapacitet. Med en robust konstruktion som kan bära upp till 200 kg, är den idealisk för att transportera allt från barnstolar till tyngre laster. C-go-serien är utrustad med en kraftfull Bafang borstlös mittmotor och ett högkapacitetsbatteri från Samsung, vilket ger en räckvidd på mellan 50 till 80 km. Lifebike C-go är en miljövänlig lösning som passar utmärkt för stadskörning och längre utflykter, och den ger din familj den flexibla mobilitet ni behöver.`}</p>
    <h2 {...{
      "id": "lifebike-classic-serie"
    }}>{`Lifebike Classic Serie`}</h2>
    <h3 {...{
      "id": "lifebike-classic-mittmotor-lux-g9-svart---fotbroms-elcykel-2024--lifebike-classic-mittmotor-lux-g9-blå---fotbroms-elcykel-2024"
    }}>{`Lifebike Classic Mittmotor Lux G9 Svart - Fotbroms Elcykel 2024 & Lifebike Classic Mittmotor Lux G9 Blå - Fotbroms Elcykel 2024`}</h3>
    <p>{`Lifebike Classic-serien kombinerar traditionell design med modern teknik. Dessa damelcyklar är utrustade med en kraftfull mittmotor från Bafang, Shimano Nexus 7-växlar, och robusta Kenda-däck som garanterar en mjuk och stabil cykelupplevelse. Classic-modellerna kan nå hastigheter upp till 25 km/h och har imponerande räckvidder, vilket gör dem perfekta för både daglig pendling och längre turer. Med praktiska funktioner som fotbroms, digital display och rymmande frontkorg, är detta en elegant och pålitlig serie för den moderna cyklisten.`}</p>
    <h2 {...{
      "id": "lifebike-delivery-serie"
    }}>{`Lifebike Delivery Serie`}</h2>
    <h3 {...{
      "id": "lifebike-delivery-movs-elcykel-2024"
    }}>{`Lifebike Delivery Movs Elcykel 2024`}</h3>
    <p>{`För den som behöver en pålitlig och kraftfull elcykel för leveransarbete, är Lifebike Delivery-serien ett utmärkt val. Med en kraftfull bakhjulsmotor och ett robust 504 Wh batteri, erbjuder denna elcykel en räckvidd på upp till 80 km. Fem assistansnivåer och en dämpad sadelstolpe säkerställer en komfortabel körning, även under långa arbetsdagar. Delivery-serien är designad för att klara tunga laster, vilket gör den perfekt för kurirer och andra leveransyrken som kräver styrka och pålitlighet.`}</p>
    <h2 {...{
      "id": "lifebike-c-pact-serie"
    }}>{`Lifebike C-pact Serie`}</h2>
    <h3 {...{
      "id": "lifebike-c-pact-g10-7vxl---grå-elcykel-2024"
    }}>{`Lifebike C-pact G10 7vxl - Grå Elcykel 2024`}</h3>
    <p>{`Lifebike C-pact är en innovativ hopfällbar elcykel som är idealisk för den moderna pendlaren eller äventyraren. Den är designad för att enkelt kunna transporteras i båtar, husbilar eller kollektivtrafik. Med sin kompakta design och robusta konstruktion kan C-pact-serien erbjuda en smidig och pålitlig körning. Utrustad med en kraftfull bakhjulsmotor, Shimano Tourney 7-växlade system och en imponerande batterikapacitet på 36V 378Wh, har den en räckvidd på upp till 70 km. Detta gör den till en perfekt följeslagare för både daglig pendling och längre äventyr.`}</p>
    <h2 {...{
      "id": "guide-för-att-välja-rätt-lifebike-elcykel"
    }}>{`Guide för att Välja Rätt Lifebike Elcykel`}</h2>
    <p>{`Att välja rätt elcykel kan vara utmanande med så många fantastiska alternativ från Lifebike. Här är några tips för att underlätta ditt beslut:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`För Familjer och Pendling`}</strong>{`: Lifebike C-go-serien är idealisk med dess robusthet och kapacitet att bära tunga laster.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Stil och Daglig Pendling`}</strong>{`: Classic-serien erbjuder en kombination av traditionell elegans och modern teknik, perfekt för daglig användning.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Arbete och Leveranser`}</strong>{`: Delivery-serien är skräddarsydd för leveransarbeten, med kraftfulla motorer och lång räckvidd.`}</li>
      <li parentName="ol"><strong parentName="li">{`För Flexibilitet och Äventyr`}</strong>{`: C-pact-serien är det bästa valet för dig som behöver en kompakt, hopfällbar elcykel med alla funktioner för både pendling och äventyr.`}</li>
    </ol>
    <p>{`Utforska vårt sortiment av Lifebike elcyklar och hitta den perfekta modellen som uppfyller alla dina behov. Välkommen till en värld av bekväm, hållbar och högpresterande cykling med Lifebike!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      